import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled('div')`
  width: 630px;
  margin: 0 auto;
  height: calc(100vh - 10vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CalendarWrap = styled('div')`
  .react-calendar {
    font-family: var(--regular);
    font-size: 16px;
    border: 0;
    width: 100%;

    button {
      background-color: #fff;
      border: 0;
      outline: none;

      &[disabled] {
        background-color: #fff;
        color: grey;
        opacity: .5;
      }
    }
  }

  .react-calendar__navigation__label__labelText,
  .react-calendar__navigation__label__labelText--from {
    color: var(--text);
    font-size: 0.875em;
    font-family: var(--medium);
    font-weight: 600;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .react-calendar__navigation {
    height: 37px;
    font-family: var(--regular);
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    button {
    min-width: 30px;
    }
  }

  .react-calendar__tile {
    border-radius: 4px;
  }

  .react-calendar__navigation__arrow {
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.35em;
  }

  // .react-calendar__navigation__next2-button,
  // .react-calendar__navigation__prev2-button {
  //   display: none;
  // }

  .react-calendar__tile--active {
    background-color: var(--green) !important;

    & ~ .react-calendar__tile {
      background-color: ${p => !p.noLightFill && '#eaf3d8'};
      border-radius: 0;
    }
  }
  .react-calendar__tile--now {
    background-color: var(--yellow) !important;
  }

  .react-calendar__month-view__weekdays {
    display: none !important;
  }
`

export const CalendarWrap1 = styled(CalendarWrap)`
  display: flex;
  justify-content: flex-end;

  ${(props) =>
    props.endDate &&
    `
      .react-calendar__tile {
        border-radius: 0;
        background-color: #eaf3d8 !important;
      }
      .react-calendar__tile--active {
        background-color: var(--green) !important;
        border-radius: 4px;
        & ~ .react-calendar__tile {
          background-color: #fff !important;
          border-radius: 4px;
      }
  }
  `}
  .react-calendar {
    .react-calendar__tile {
      background-color: #eaf3d8;
      border: 0;
      outline: none;

      &[disabled] {
        background-color: #fff ${(props) => props.startDate && '!important'};
      }
    }
  }

  .react-calendar__tile--active {
    background-color: red;
  }
`

export const Row = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 78.125em;
  margin: 0 auto;
  height: 10vh;
`

export const DateBlock = styled('div')`
  font-size: 1.25em;
  height: 3.5em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--medium);
  color: var(--blue);
  border: 2px solid var(--blue);
  margin-top: 2em;
  margin-bottom: 2em;

  span {
    font-family: var(--bold);
    margin-right: 0.3em;

    &:last-child {
      margin-left: 0.3em;
    }
  }
`

export const ForgetLink = styled(Link)`
  font-family: var(--medium);
  font-size: 1em;
  color: var(--orange);
  margin: 1em auto;
  text-decoration: underline;
`
