import { Select, Radio, Divider } from 'antd'
import moment from 'moment'
import React, { useEffect, useState, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import closeIcon from '../../../../../assets/images/close-grey.svg'
import deleteFileIcon from '../../../../../assets/images/delete-orange.svg'
import Button from '../../../../../components/common/Button/button'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import CalendarCustom from '../../../../../components/common/Calendar'
import Input from '../../../../../components/common/Input'
import { checkRole } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import { Cancel, Overlay, Row, Title, Wrap, Subtitle } from './style'
import FileList from './FileList'
import Draggable from "react-draggable";
import { api, downloadDoc } from '../../../../../api'


const Modal = ({
  data,
  currentDeficiencyBlock,
  control,
  getValues,
  loadingDueDate,
  onClose,
  onSubmit,
  handleSubmit,
  errors,
  complete,
  assigneNew,
  setAssigneNew,
  taskID,
  tasks,
  loadingFile,
  complianceItem,
  redCircleDate,
  setTemporaryFiles,
  temporaryFiles,
  loading,
  activeTab,
  isReportSource = false,
  isLicenseSource = false,
  isRenewalsReporting = false,
  getConsiderationData,
  receiveGetReadyData,
  licenseStatus,
  handleOpen,
}) => {

  const history = useHistory()
  const params = useParams()
  const store = useStore()
  const [taskFiles, setFiles] = useState()
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [docStatus, setDocStatus] = useState('working')
  const docStatusList = [
    {
      key: 0,
      value: 'working',
      label: 'Working',
    },
    {
      key: 1,
      value: 'finalized',
      label: 'Finalized',
    },
    {
      key: 2,
      value: 'submitted',
      label: 'Submitted',
    },
    {
      key: 3,
      value: 'deficiency',
      label: 'Deficiency',
    },
    {
      key: 3,
      value: 'other',
      label: 'Other',
    },
  ]

  const patternSet = {
    update: 'Update Policy and Procedure: ',
    create: 'Create Policy and Procedure: ',
    customer_contracts_update_template: 'Customer Contracts: Update Template: ',
    customer_contracts_create_amendment: 'Customer Contracts: Create Amendment: ',
    pharmacy_contracts_update_template: 'Pharmacy Contracts: Update Template: ',
    pharmacy_contracts_create_amendment: 'Pharmacy Contracts: Create Amendment: ',
    rebate_contracts_update_template: 'Rebate Contracts: Update Template: ',
    rebate_contracts_create_amendment: 'Rebate Contracts: Create Amendment: ',
    vendor_contracts_update_template: 'Vendor Contracts: Update Template: ',
    vendor_contracts_create_amendment: 'Vendor Contracts: Create Amendment: ',
    other_contracts: 'Other Contracts: ',
    internal: 'Create Internal Communication: ',
    pharmacy: 'Create Pharmacy Communication: ',
    customer: 'Create Customer Communication: ',
    vendor: 'Create Vendor Communication: ',
    other: 'Create Other Communication: ',
    update_formulary: 'Update Formulary: ',
    update_clinical_guidelines: 'Update Clinical Guidelines: ',
  }

  const immediateComplete = store?.immediateCompleteTaskProcess

  const disabled = !complete && (isReportSource ? store.user?.[0]?.report_source_role === 'member' : store.user?.[0]?.member_type === 'member')

  const isComplianceMember = checkRole([store.user?.[0]?.member_type], ['member']);
  const isReportMember = checkRole([store.user?.[0]?.report_source_role], ['member']);
  const isLicenseMember = checkRole([store.user?.[0]?.license_source_role], ['member']);

  const accessAllowed = isReportSource ? isReportMember : isLicenseSource ? isLicenseMember : isComplianceMember;

  const nodeRef = useRef(null);
  const trackPos = (data) => {
    setPosition({ x: data.x, y: data.y });
  };
  const handleStart = () => { };
  const handleEnd = () => { };

  const toggleConsiderationsCheckbox = (name, value) => {
    const field = name === 'other_contracts'
      || name === 'vendor'
      || name === 'internal'
      || name === 'pharmacy'
      || name === 'other'
      || name === 'customer'
      || name === 'update'
      || name === 'create'
      || name === 'other_contracts'
      || name === 'update_formulary'
      || name === 'update_clinical_guidelines'
      ? name + '_tasks' : name

    api(`/legislative/${complianceItem}/toggle_considerations_checkboxes/`, { [field]: value }, 'PATCH').then(() => {
      getConsiderationData()
    })
  }

  const toggleGetReafyCheckbox = (name, value) => {
    const url = isLicenseSource ?
      params.tabName === 'renewals_reporting'
        ? `/renewals_reporting/${complianceItem}/get_ready_tasks_information/`
        : `/license_company/${complianceItem}/determination/${store?.currentDetermination?.id}/get_ready_tasks_information/`
      : `/report_company/${complianceItem}/get_ready_tasks_information/`
    api(url, { checkbox_id: store.getReadyTask.id }, 'PATCH').then(() => {
      receiveGetReadyData(isLicenseSource, isReportSource, isRenewalsReporting, store?.currentDetermination?.id)
    })
  }

  // function truncateTextToFirstColon(text) {
  //   const index = text.indexOf(':');
  //   if (index !== -1) {
  //     return text.substring(0, index + 1); // +1 чтобы включить двоеточие
  //   }
  //   return text;
  // }
  const handleClose = (immediateComplete = false) => {
    const reportSourcePaths = {
      myTasks: `/report-source/myTasks?report=${complianceItem}`,
      main: `/report-source/main?report=${complianceItem}`,
      apcd: `/report-source/apcd?report=${complianceItem}`,
      all: `/report-source/all?report=${complianceItem}`,
      closed: `/report-source/closed?report=${complianceItem}`,
    };

    const licenseSourcePaths = {
      myTasks: `/license-source/myTasks?license_item=${complianceItem}`,
      all: `/license-source/all?license_item=${complianceItem}`,
      apcd: `/license-source/apcd?license_item=${complianceItem}`,
      monitor: `/license-source/monitor?license_item=${complianceItem}`,
      closed: `/license-source/closed?license_item=${complianceItem}`,
      na: `/license-source/na?license_item=${complianceItem}`,
      need: `/license-source/need?license_item=${complianceItem}`,
      renewals_reporting: `/license-source/renewals_reporting?license_item=${complianceItem}`,
      assigned: `/license-source/assigned?license_item=${complianceItem}`,
      pending_tasks: `/license-source/pending?license_item=${complianceItem}`,
      ia_all: `/license-source/ia_all?license_item=${complianceItem}`,
      ia_unassigned: `/license-source/ia_unassigned?license_item=${complianceItem}`,
      ia_in_progress: `/license-source/ia_in_progress?license_item=${complianceItem}`,
      ia_in_progress_ready_for_final_review: `/license-source/ia_in_progress_ready_for_final_review?license_item=${complianceItem}`,
      ia_in_progress_submitted_to_govt: `/license-source/ia_in_progress_submitted_to_govt?license_item=${complianceItem}`,
      ia_in_progress_deficiency: `/license-source/ia_in_progress_deficiency?license_item=${complianceItem}`,
    };

    const trackingPaths = {
      myTasks: `/tracking/myTasks?compliance_item=${complianceItem}`,
      all: store.user[0].member_type === 'member'
        ? `/tracking/all?compliance_item=${complianceItem}`
        : `/tracking/main?compliance_item=${complianceItem}`,
      closed: store.user[0].member_type === 'member'
        ? `/tracking/closed?compliance_item=${complianceItem}`
        : `/tracking/main?compliance_item=${complianceItem}`,
      default: `/tracking/main?compliance_item=${complianceItem}`,
    };

    if (isReportSource && localStorage.getItem('reportInLs') !== 'true') {
      history.push(reportSourcePaths[activeTab] || reportSourcePaths.main);
    } else if (isLicenseSource || isRenewalsReporting || activeTab === 'apcd') {
      history.push(licenseSourcePaths[activeTab] || licenseSourcePaths.all);
    } else {
      history.push(trackingPaths[activeTab] || trackingPaths.default);
    }

    if (!immediateComplete) {
      if (isReportSource || isLicenseSource) {
        if (store.getReadyTask) {
          toggleGetReafyCheckbox(store.getReadyTask.id, false);
        }
        store.setSingleValue('getReadyTask', null);
      } else {
        toggleConsiderationsCheckbox(store.considerationTask, false);
        store.setSingleValue('considerationTask', null);
      }
    }

    onClose();
    if (immediateComplete) {
      store.setSingleValue('immediateCompleteTaskProcess', false);
      setAssigneNew(true);
      handleOpen();
    }
  };

  const addFile = (e) => {
    setTemporaryFiles([...temporaryFiles, [...e.target.files, docStatus]])
  }
  const modifyLastFileStatus = (status) => {
    setTemporaryFiles([...temporaryFiles.slice(0, -1), [...temporaryFiles.slice(-1)[0].slice(0, -1), status]])
  }

  const deleteFile = (name) => {
    const filtered = temporaryFiles.filter((item) => item[0].name !== name)
    setTemporaryFiles(filtered)
  }

  const deleteTaskFile = (name, id) => {
    const filtered = taskFiles.filter((item) => item.name !== name)
    setFiles(filtered)
    isReportSource
      ? store.deleteReportDocument(complianceItem, id, null)
      : isLicenseSource
        ? store.deleteLicenseDocument(complianceItem, id, null, isRenewalsReporting)
        : store.deleteDocument(complianceItem, null)

  }

  const isPatternText = complete
    ? false
    : isReportSource || isLicenseSource
      ? (assigneNew
        ? (!!store.getReadyTask)
        : (data?.type !== 'default')
      )
      : (assigneNew
        ? (!!store.considerationTask)
        : (data?.type !== 'default'))

  const getLabel = () => {
    if (complete) {
      return 'Task Comment';
    }

    const baseLabel = 'Task Description';
    if (isPatternText) {
      return (
        <>
          {baseLabel}: <strong>{getPatternText()}</strong>
        </>
      );
    }

    return baseLabel;
  };

  const getTitle = () => {
    if (complete) {
      return data?.type !== 'default' && !isReportSource && !isLicenseSource
        ? 'Complete Considerations Task:'
        : data?.type === 'deficiency' ?
          'Complete Deficiency Task:'
          : 'Complete a Task:';
    }

    if (assigneNew) {
      if (isReportSource || isLicenseSource) {
        return store.getReadyTask
          ? 'Assign a Checklist Task:'
          : 'Assign a Task:';
      }

      return store.considerationTask
        ? 'Assign a Consideration Task:'
        : 'Assign a Task:';
    }

    return data?.type !== 'default' && !isReportSource && !isLicenseSource
      ? 'Edit Consideration Task:'
      : 'Edit a Task:';
  };

  const getPatternText = () => {
    if (complete) {
      return '';
    }

    if (isReportSource || isLicenseSource) {
      if (assigneNew) {
        return store.getReadyTask ? store.getReadyTask.text + ' ' : '';
      }
      return data?.task_template ? `${data.task_template.trim()}: ` : '';
    }

    return assigneNew
      ? patternSet[store.considerationTask]
      : patternSet[data?.type];
  };


  useEffect(() => {
    if (tasks.length > 0) {
      setFiles(getFiles(tasks, taskID))
    }
  }, [tasks])


  return (
    <>
      <Overlay onClick={() => handleClose(immediateComplete)} />
      <Draggable
        positionOffset={{ x: '-50%', y: '-50%' }}
        nodeRef={nodeRef}
        onDrag={(e, data) => trackPos(data)}
        onStart={handleStart}
        onStop={handleEnd}
        cancel=".no-drag"
      >

        <Wrap name={'task modal'} ref={nodeRef}>
          <img
            src={closeIcon}
            alt='close-icon'
            onClick={() => handleClose(immediateComplete)}
            id='close'
          />
          <Title>{getTitle()}
          </Title>
          {!complete && !assigneNew &&
            <>
              <Subtitle className="no-drag">
                <strong>Assigned by: </strong>{store.getMember(data?.assigner)}
              </Subtitle>
              <Subtitle style={{ marginBottom: 20 }} className="no-drag">
                <strong>Date: </strong>{isReportSource
                  ? moment(data?.created).format('MM/DD/YYYY')
                  : moment(data?.assigned_date).format('MM/DD/YYYY')}
              </Subtitle>
            </>
          }
          {!complete && (
            <Row className="no-drag">
              <Input
                type={'select'}
                label={'Task Owner'}
                validation={{ required: true }}
                control={control}
                disabled={disabled}
                dropdownStyle={{ position: 'absolute', zIndex: 9999999 }}
                name={'assignee'}
                showSearch
                customOptions={isReportSource
                  ? store.members
                    .filter((item) => item.have_access_to_report_source)
                    .map(
                      (item) =>
                        item.is_active && (
                          <Select.Option value={item.id} key={item.id}>
                            {item.first_name + ' ' + item.last_name}
                          </Select.Option>
                        )
                    )
                  : isLicenseSource
                    ? store.members
                      .filter((item) => item.have_access_to_license_source)
                      .map(
                        (item) =>
                          item.is_active && (
                            <Select.Option value={item.id} key={item.id}>
                              {item.first_name + ' ' + item.last_name}
                            </Select.Option>
                          )
                      )
                    : store.members
                      .filter((item) => item.have_access_to_tracking)
                      .map(
                        (item) =>
                          item.is_active && (
                            <Select.Option value={item.id} key={item.id}>
                              {item.first_name + ' ' + item.last_name}
                            </Select.Option>
                          )
                      )}
                smallLabel
                error={errors?.assignee && 'This field is required'}

              />
              <Controller
                control={control}
                name={'due_date'}
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <CalendarCustom
                    handleChange={(value) => {
                      onChange(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                    }}
                    incomeValue={value}
                    loading={loadingDueDate}
                    disabled={disabled}
                    required
                    label={'Task Due Date'}
                    error={errors?.due_date && 'This field is required'}
                    redCircleDate={redCircleDate}
                  />
                )}
              />
            </Row>
          )}
          {complete && data?.type === 'deficiency' &&
            <>
              <div
                style={{
                  borderTop: '1px solid var(--grey)',
                  margin: '20px 0',
                }}
              />
              <Subtitle className="no-drag" style={{ fontFamily: 'var(--semiBold)' }}>
                <strong>Deficiency - {data.deficiency_due_date}</strong>
              </Subtitle>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Subtitle style={{ fontFamily: 'var(--semiBold)', marginRight: 5 }} className="no-drag">Subject:</Subtitle>
                <Subtitle className="no-drag">{data.subject}</Subtitle>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Subtitle style={{ fontFamily: 'var(--semiBold)', marginRight: 5 }} className="no-drag">Description:</Subtitle>
                <Subtitle className="no-drag">{data.deficiency_description}</Subtitle>
              </div>
              {currentDeficiencyBlock[0]?.files?.length > 0 &&
                currentDeficiencyBlock[0]?.files.map((item, index) => (
                  <ButtonOrange
                    text={item.file_name}
                    key={index}
                    onClick={() => downloadDoc(item.id, item.file_name, isReportSource, isLicenseSource, isRenewalsReporting, complianceItem, store?.currentLegislative?.report?.id)}
                  />
                ))
              }
              <div
                style={{
                  borderTop: '1px solid var(--grey)',
                  margin: '20px 0',
                }}
              />
            </>
          }
          {complete && !immediateComplete && (
            <>
              <Subtitle className="no-drag">
                <strong>Assigned by: </strong>{store.getMember(data?.assigner)}
              </Subtitle>
              <Subtitle style={{ marginBottom: 20 }} className="no-drag">
                <strong>Task: </strong>{data?.description}
              </Subtitle>
            </>
          )}
          <Row style={{ gridTemplateColumns: '1fr' }} className="no-drag">
            <Input
              type={'autoTextarea'}
              label={getLabel()}
              control={control}
              disabled={disabled}
              name={'description'}
              smallLabel
              error={errors?.description && 'This field is required'}
              editing={!complete && !assigneNew}
              validation={{
                required: true,
                validate: {
                  notEmpty: value => value.trim() !== '' || !value || 'This field is required',
                },
              }}
            />
          </Row>
          {complete && (store?.currentLegislative?.status !== 'not_assessed' &&
            store?.currentLegislative?.status !== 'na' &&
            store?.currentLegislative?.status !== 'monitor')
            && (
              <div className="no-drag">
                <Input
                  type={'file'}
                  name={'file'}
                  label={`Upload Document ${immediateComplete ? '(Optional):' : ':'}`}
                  control={control}
                  styleRow={{ marginTop: 10, marginBottom: 10 }}
                  handleChange={(e) => addFile(e)}
                  loading={loadingFile}
                  multiple
                  smallLabel
                />
              </div>
            )}
          <div className="no-drag">
            <FileList temporaryFiles={temporaryFiles} setTemporaryFiles={setTemporaryFiles} deleteFile={deleteFile} isLicenseSource={isLicenseSource} isRenewalsReporting={isRenewalsReporting} isReportSource={isReportSource} />
          </div>
          {complete && !assigneNew && (isReportSource
            || (isLicenseSource && store?.currentLegislative?.status !== 'not_assessed'
              &&
              store?.currentLegislative?.status !== 'na' &&
              store?.currentLegislative?.status !== 'monitor'
            ) || isRenewalsReporting) && (
              <div style={{ marginTop: 15 }} className="no-drag">
                <Radio.Group
                  onChange={(e) => {
                    setDocStatus(e.target.value)
                    temporaryFiles.length && modifyLastFileStatus(e.target.value)
                  }}
                  defaultValue={docStatus}
                >
                  {docStatusList.map(({ label, value }) => (
                    <Radio value={value} key={value}>
                      {label}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            )}

          {complete && taskFiles && taskFiles.length > 0 &&
            <FileList
              temporaryFiles={taskFiles}
              setTemporaryFiles={setFiles}
              deleteFile={(name, id) => deleteTaskFile(name, id)}
              isLicenseSource={isLicenseSource}
              isRenewalsReporting={isRenewalsReporting}
              isReportSource={isReportSource}
              isTaskFile={true}
              downloadDoc={(id, name) => downloadDoc(
                id,
                name,
                isReportSource,
                isLicenseSource,
                isRenewalsReporting,
                store?.currentLegislative?.id,
                store?.currentLegislative?.report?.id
              )}
            />
          }

          {/* {complete &&
            taskFiles?.map((item, index) => {
              return (
                <>
                  <ButtonOrange
                    text={item.name}
                    key={index}
                    link={item.link}
                    defaultLink
                  />
                </>
              )
            })} */}
          {(complete ||
            !accessAllowed) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 40,
                }}
                className="no-drag"
              >
                <Cancel onClick={() => handleClose(immediateComplete)}>{immediateComplete ? 'Go Back' : 'Cancel'}</Cancel>
                <div style={{ display: 'flex' }}>
                  {assigneNew && (!!store.getReadyTask || !!store.considerationTask) &&
                    <Button
                      style={{ marginRight: 10 }}
                      text={'Complete a Task'}
                      onClick={() => handleSubmit((data) => onSubmit(data, true))()}
                      loading={loading}
                    />
                  }
                  <Button
                    text={complete ? 'Complete a Task' : assigneNew ? 'Assign a Task' : 'Edit a Task'}
                    onClick={() => handleSubmit((data) => onSubmit(data, false))()}
                    loading={loading}
                  />
                </div>

              </div>
            )}
        </Wrap>
      </Draggable>
    </>
  )
}

export default Modal

export const getFiles = (tasks, taskID) => {
  let files = tasks.find((item) => +item.id === +taskID)?.files

  if (files?.length > 0) {
    return files.map((item) => {
      const splitedString = item.file_name.split('/')
      return {
        name: splitedString[splitedString.length - 1],
        link: item.file,
        id: item.id,
      }
    })
  }
}
