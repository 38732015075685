import React, { useState } from 'react';
import TaskItem from './item';
import Button from '../../../../../components/common/Button/button';
import expandIcon from '../../../../../assets/images/expand.svg';
import hideIcon from '../../../../../assets/images/hide.svg'

const DeficiencyBlock = ({ item, isReportSource, isLicenseSource, disabled, store, handleTaskClick, isCompleted, onClick, accessAllowed, onEditDeficiency }) => {
  const [expanded, setExpanded] = useState(false);

  const { subject, tasks, deficiency_date } = item;

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div style={{ padding: 0, marginTop: 0 }}>
      <div style={{ display: 'flex', alignItems: 'center', }}>
        <Button
          text={`Deficiency - ${deficiency_date}:`}
          style={{
            fontSize: 14,
            backgroundColor: '#fff',
            border: 'none',
            color: 'var(--blue)',
            padding: 0,
            fontFamily: 'var(--bold)',
          }}
          onClick={toggleExpanded}
          icon={expanded ? hideIcon : expandIcon}
        />
        <div style={{
          fontFamily: 'var(--regular)',
          fontSize: 14,
          cursor: 'pointer',
          marginLeft: 3,
          marginBottom: 3,
          color: 'var(--blue)',
          textDecoration: 'underline',
        }}
          onClick={() => {
            onEditDeficiency(() => store.setSingleValue('currentDeficiencyBlock', item))
          }}
        >
          {subject}
        </div>
      </div>
      {expanded && (
        <div style={{ marginTop: 10 }}>
          {tasks.map((item) => (
            <TaskItem
              key={item.id}
              item={item}
              isReportSource={isReportSource}
              isLicenseSource={isLicenseSource}
              disabled={disabled}
              accessAllowed={accessAllowed}
              onClick={() => onClick(item)}
              handleTaskClick={handleTaskClick}
              store={store}
              isCompleted={isCompleted}
              deficiencyData={item}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DeficiencyBlock;