import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const MAX_CHARACTERS = 150;
const COLLAPSED_HEIGHT = 45;

const TextWrapper = styled.div`
  font-family: var(--regular);
  font-size: 0.875em;
  color: var(--text);
  letter-spacing: 0.3px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
  position: relative;
  transition: height 0.3s ease-in-out;

  mark {
    background-color: var(--yellow);
  }

  pre {
    font-family: var(--regular);
  }

  b {
    font-family: var(--bold);
  }

  p {
    margin: 0;
  }

  a {
    color: var(--green);
    text-decoration: underline;
  }

  .file-deleted {
    text-decoration: underline;
    color: #c4c4c4;
  }

  mention {
    color: var(--orange);
    font-family: var(--bold);
  }
  ${({ expanded, fullHeight }) => !expanded && fullHeight > COLLAPSED_HEIGHT && `
    height: ${COLLAPSED_HEIGHT}px;
    overflow: hidden;
  `}
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: var(--orange);
  cursor: pointer;
  font-family: var(--regular);
  text-decoration: underline;
  display: block;
  padding: 0;
  font-size: 14px;
  width: 68px;
`;

const stripHtmlTags = (html) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  return tempElement.textContent || tempElement.innerText || '';
};

const ExpandableText = ({ content }) => {
  const [expanded, setExpanded] = useState(false);
  const textRef = useRef(null);
  const [fullHeight, setFullHeight] = useState(0);

  const plainText = stripHtmlTags(content); // Убираем HTML-теги
  const isTruncated = plainText.length > MAX_CHARACTERS;

  useEffect(() => {
    if (textRef.current) {
      setFullHeight(textRef.current.scrollHeight);
    }
  }, [content]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextWrapper ref={textRef} expanded={expanded} fullHeight={fullHeight}>
        {parse(content)}
      </TextWrapper>

      {isTruncated && fullHeight > COLLAPSED_HEIGHT && (
        <ToggleButton onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Show Less' : 'Show More'}
        </ToggleButton>
      )}
    </div>
  );
};

export default ExpandableText;