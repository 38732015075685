import React from 'react'
import Moment from 'react-moment'
import { useHistory, useParams } from 'react-router-dom'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../../helpers/helpers'
import useModal from '../../../../../hooks/useModal'
import { useStore } from '../../../../../mobx-store/context'
import ModalCompleted from '../Modal/ModalCompleted'
import { Item } from './style'
import TaskItem from './item'
import DeficiencyBlock from './deficiencyBlock'


const List = ({
  tasks,
  deficiencyTasksBlocks,
  setCurrentDeficiencyBlock,
  currentDeficiencyBlock,
  onEditDeficiency,
  currentTask,
  setCurrentTask,
  onModalOpen,
  onComplete,
  taskID,
  complianceItem,
  data,
  isMyTasks,
  getTasks,
  getDeficiencyTasksBlocks,
  activeTab,
  setEdit,
  isReportSource = false,
  isLicenseSource = false,
  isRenewalsReporting = false,
  setShouldComplete,
  disabled,
}) => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const { visible, handleOpen, handleClose } = useModal()
  const isCompleted = (item) => item.status === 'complete'
  const handleCurrentTask = (item) => setCurrentTask(item)
  const handleCurrentDeficiencyBlock = (item) => {
    const filteredBlocks = deficiencyTasksBlocks.filter(
      (block) => block.id === item.deficiency_id
    );
    setCurrentDeficiencyBlock(filteredBlocks);
  };

  const defaultTasks = tasks.filter((task) => task.type === 'default');
  const otherTasks = tasks.filter((task) => task.type !== 'default');

  const onClick = (item) => {
    if (isCompleted(item)) {
      handleCurrentTask(item)
      handleCurrentDeficiencyBlock(item)
      handleOpen()
    } else {
      handleCurrentTask(item)
      handleCurrentDeficiencyBlock(item)
      onModalOpen(item)
    }
    const reportInLocalStorage = localStorage.getItem('reportInLs') === 'true';

    const routePath = isReportSource && !reportInLocalStorage
      ? '/report-source/'
      : isLicenseSource || reportInLocalStorage
        ? '/license-source/'
        : '/tracking/';

    const tabRoutes = {
      closed: 'closed',
      all: 'all',
      myTasks: 'myTasks',
      main: 'main',
      apcd: 'apcd',
      monitor: 'monitor',
      na: 'na',
      need: 'need',
      not_assessed: 'not_assessed',
      renewals_reporting: 'renewals_reporting',
      assigned: 'assigned',
      pending_tasks: 'pending_tasks',
      all_renewals: 'all_renewals',
      held: 'held',
      all_renewals: 'all_renewals',
      ia_all: 'ia_all',
      ia_unassigned: 'ia_unassigned',
      ia_in_progress: 'ia_in_progress',
      ia_in_progress_ready_for_final_review: 'ia_in_progress_ready_for_final_review',
      ia_in_progress_submitted_to_govt: 'ia_in_progress_submitted_to_govt',
      ia_in_progress_deficiency: 'ia_in_progress_deficiency',
    };

    const routeType = isMyTasks
      ? tabRoutes[params.tabName] || 'myTasks'
      : tabRoutes[activeTab] || 'closed';

    const queryParam = isReportSource && localStorage.getItem('reportInLs') !== 'true'
      ? 'report='
      : isLicenseSource || localStorage.getItem('reportInLs') === 'true'
        ? 'license_item='
        : 'compliance_item=';
    const complianceItemId = complianceItem || params.id;
    const taskID = item.id;
    const route = `${routePath}${routeType}?${queryParam}${complianceItemId}&taskID=${taskID}`;
    history.push(route);
  }

  const determineRoutePath = () => {
    const reportInLocalStorage = localStorage.getItem('reportInLs') === 'true';
    if (isReportSource && !reportInLocalStorage) {
      return '/report-source/';
    }
    if (isLicenseSource || reportInLocalStorage) {
      return '/license-source/';
    }
    return '/tracking/';
  };

  const determineRouteType = () => {
    const routeMap = {
      main: 'main',
      apcd: 'apcd',
      all: 'all',
      monitor: 'monitor',
      na: 'na',
      need: 'need',
      not_assessed: 'not_assessed',
      renewals_reporting: 'renewals_reporting',
      assigned: 'assigned',
      pending_tasks: 'pending_tasks',
      all_renewals: 'all_renewals',
      held: 'held',
      all_renewals: 'all_renewals',
      ia_all: 'ia_all',
      ia_unassigned: 'ia_unassigned',
      ia_in_progress: 'ia_in_progress',
      ia_in_progress_ready_for_final_review: 'ia_in_progress_ready_for_final_review',
      ia_in_progress_submitted_to_govt: 'ia_in_progress_submitted_to_govt',
      ia_in_progress_deficiency: 'ia_in_progress_deficiency',
    };

    if (isMyTasks) {
      return activeTab === 'closed'
        ? 'closed'
        : activeTab === 'all'
          ? 'all'
          : 'myTasks';
    }

    return routeMap[activeTab] || 'closed';
  };

  const determineQueryParam = () => {
    const reportInLocalStorage = localStorage.getItem('reportInLs') === 'true';
    if (isReportSource && !reportInLocalStorage) {
      return 'report';
    }
    if (isLicenseSource || reportInLocalStorage) {
      return 'license_item';
    }
    return 'compliance_item';
  };

  const handleModalClose = () => {
    handleClose();
    const routePath = determineRoutePath();
    const routeType = determineRouteType();
    const queryParam = determineQueryParam();
    const complianceItemId = complianceItem || params.id;

    const route = `${routePath}${routeType}?${queryParam}=${complianceItemId}`;
    history.push(route);
  };

  const fetchRoutePath = () => {
    const reportInLocalStorage = localStorage.getItem('reportInLs') === 'true';
    if (isReportSource && !reportInLocalStorage) return '/report-source/';
    if (isLicenseSource || reportInLocalStorage) return '/license-source/';
    return '/tracking/';
  };

  const resolveRouteType = () => {
    const routeMap = {
      main: 'main',
      apcd: 'apcd',
      all: 'all',
      monitor: 'monitor',
      na: 'na',
      need: 'need',
      not_assessed: 'not_assessed',
      renewals_reporting: 'renewals_reporting',
      assigned: 'assigned',
      pending_tasks: 'pending_tasks',
      all_renewals: 'all_renewals',
      held: 'held',
      ia_all: 'ia_all',
      ia_unassigned: 'ia_unassigned',
      ia_in_progress: 'ia_in_progress',
      ia_in_progress_ready_for_final_review: 'ia_in_progress_ready_for_final_review',
      ia_in_progress_submitted_to_govt: 'ia_in_progress_submitted_to_govt',
      ia_in_progress_deficiency: 'ia_in_progress_deficiency',
    };
    return isMyTasks ? 'myTasks' : routeMap[activeTab] || 'closed';
  };

  const resolveQueryParam = () => {
    if (isReportSource) return 'report';
    if (isLicenseSource) return 'license_item';
    return 'compliance_item';
  };

  const handleTaskClick = (item, isCompletedAction) => {
    handleCurrentTask(item);
    handleCurrentDeficiencyBlock(item)
    if (isCompletedAction) {

      handleOpen();

    } else {
      if (localStorage.getItem('taskReOpen') === 'true') {
        onModalOpen(item);
      } else {
        if (data.status === 'closed' || disabled) return;
        onComplete();
      }
    }

    const routePath = fetchRoutePath();
    const routeType = resolveRouteType();
    const queryParam = resolveQueryParam();
    const complianceItemId = complianceItem || params.id;
    const taskID = item.id;

    const route = `${routePath}${routeType}?${queryParam}=${complianceItemId}&taskID=${taskID}`;
    history.push(route);
  };

  const isAdminOrComplianceOwner = checkRole([store.user?.[0]?.member_type], ['admin', 'compliance_owner', 'owner']);
  const isAdminOrReportOwner = checkRole([store.user?.[0]?.report_source_role], ['admin', 'report_owner', 'owner']);
  const isAdminorLicenseOwner = checkRole([store.user?.[0]?.license_source_role], ['admin', 'license_owner']);
  const accessAllowed = isAdminOrComplianceOwner || isAdminOrReportOwner || isAdminorLicenseOwner;

  const renderTaskBlock = (tasksToRender) => (
    <div>
      {tasksToRender.map((item) => (
        <TaskItem
          key={item.id}
          item={item}
          isReportSource={isReportSource}
          isLicenseSource={isLicenseSource}
          disabled={disabled}
          accessAllowed={accessAllowed}
          onClick={() => onClick(item)}
          handleTaskClick={handleTaskClick}
          store={store}
          isCompleted={isCompleted}
        />
      ))}
    </div>
  );

  const renderDeficiencyBlock = (deficiencyTasksBlocks) => (
    <div>
      {deficiencyTasksBlocks.map((block) => (
        <DeficiencyBlock
          onEditDeficiency={onEditDeficiency}
          item={block}
          key={block.id}
          isReportSource={isReportSource}
          isLicenseSource={isLicenseSource}
          disabled={disabled}
          store={store}
          handleTaskClick={handleTaskClick}
          isCompleted={isCompleted}
          onClick={onClick}
          accessAllowed={accessAllowed}
        />
      ))}
      <div
        style={{
          borderTop: '2px solid var(--blue)',
          margin: '20px 0',
        }}
      />
    </div>
  );

  return (
    <div>
      {visible && (
        <ModalCompleted
          isReportSource={isReportSource}
          isLicenseSource={isLicenseSource}
          isRenewalsReporting={isRenewalsReporting}
          data={currentTask}
          onClose={handleModalClose}
          tasks={tasks}
          currentDeficiencyBlockTasks={currentDeficiencyBlock[0]?.tasks || []}
          taskID={taskID}
          complianceItem={complianceItem || params.id}
          getTasks={getTasks}
          getDeficiencyTasksBlocks={getDeficiencyTasksBlocks}
          handleTaskClick={handleTaskClick}
          setShouldComplete={setShouldComplete}
          disabled={disabled}
        />
      )}
      <Item
        bordered={true}
        style={{
          fontFamily: 'var(--bold)',
        }}
      >
        <div>Assigned To:</div>
        <div>Task:</div>
        <div style={{ paddingLeft: 20 }}>Due:</div>
        <div>Status:</div>
      </Item>
      {deficiencyTasksBlocks.length > 0 && !disabled && renderDeficiencyBlock(deficiencyTasksBlocks)}
      {renderTaskBlock(otherTasks)}
      {defaultTasks.length > 0 && otherTasks.length > 0 && (
        <div
          style={{
            borderTop: '2px solid var(--blue)',
            margin: '20px 0',
          }}
        />
      )}
      {renderTaskBlock(defaultTasks)}
    </div>
  )
}

export default List
